import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import dateFormat from 'shared/helper/dateFormat'
import {
	ActiveFields,
	RueckkonvertierbareRenteState,
	TAuszahlungsmodellProperty,
	TAuszahlungsModellType,
} from '../PayoutModel'
import { forwardRef, useMemo } from 'react'
import { AustrittsAuszahlungsOptionenResponse } from 'hooks/useApi'
import { currencyFormat } from 'shared/helper/numberFormats'
import { Trans } from 'react-i18next'
import { t } from 'i18next'

interface PayoutModelFormProps {
	updatedData: any
	simulate: boolean
	rueckkonvertierbareRente: RueckkonvertierbareRenteState
	auszahlungsoption: AustrittsAuszahlungsOptionenResponse
	handleFieldActive: (field: keyof ActiveFields, value: string) => void
	selectedPayoutModel: string
	handleSelectedPayoutModelChange: (value: TAuszahlungsModellType) => void
	model: TAuszahlungsmodellProperty
	activeFields: ActiveFields
	summen: any
	onSubmit: (data: any) => void
	ref: any
	getFinalBalanceClass: () => string
	rueckkonvertierbareRenteMax?: number
	hasRueckkonvertierbareRente?: boolean
}

function isAMFormFieldActive(auszahlungsModell: TAuszahlungsModellType, fieldName: string): boolean {
	const activeAuszahlungsModellFields: { [key in TAuszahlungsModellType]?: string[] } = {
		lumpSum: ['versorgungsGuthabenEintrittLeistungsfall', 'desiredPaymentOptions', 'lumpSumTotal', 'lumpSum'],
		mixed: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'instalments10Years',
			'totalAmountInstalments10Years',
			'instalments10YearsRatePerYear',
			'amountFirstInstalment10Years',
			'remainingBalance',
			'instalments20Years',
			'totalAmountInstalments20Years',
			'instalments20YearsRatePerYear',
			'amountFirstInstalment20Years',
			'remainingBalance2',
			'lumpSum',
			'lumpSumTotal',
		],

		instalments10Years: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'instalments10Years',
			'totalAmountInstalments10Years',
			'instalments10YearsRatePerYear',
			'amountFirstInstalment10Years',
		],
		instalments20Years: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'instalments20Years',
			'totalAmountInstalments20Years',
			'instalments20YearsRatePerYear',
			'remainingBalance2',
			'amountFirstInstalment20Years',
		],
	}
	const fields = activeAuszahlungsModellFields[auszahlungsModell] || []

	return !fields.includes(fieldName)
}

function isAMFormFieldHidden(auszahlungsModell: TAuszahlungsModellType, fieldName: string): boolean {
	const availableAuszahlungsModellFields: { [key in TAuszahlungsModellType]?: string[] } = {
		lumpSum: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'lumpSum',
			'lumpSumTotal',
			'remainingBalanceFinal',
			'dateOneTimeAmount',
		],
		mixed: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'instalments10Years',
			'totalAmountInstalments10Years',
			'instalments10YearsRatePerYear',
			'amountFirstInstalment10Years',
			'remainingBalance',
			'instalments20Years',
			'totalAmountInstalments20Years',
			'instalments20YearsRatePerYear',
			'amountFirstInstalment20Years',
			'lumpSum',
			'lumpSumTotal',
			'remainingBalanceFinal',
			'dateFirstRate',
			'dateOneTimeAmount',
		],

		instalments10Years: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'instalments10Years',
			'totalAmountInstalments10Years',
			'instalments10YearsRatePerYear',
			'amountFirstInstalment10Years',
			'remainingBalanceFinal',
			'dateFirstRate',
		],
		instalments20Years: [
			'versorgungsGuthabenEintrittLeistungsfall',
			'desiredPaymentOptions',
			'instalments20Years',
			'totalAmountInstalments20Years',
			'instalments20YearsRatePerYear',
			'amountFirstInstalment20Years',
			'remainingBalanceFinal',
			'dateFirstRate',
		],
	}
	const fields = availableAuszahlungsModellFields[auszahlungsModell] || []

	return !fields.includes(fieldName)
}

export const PayoutModelForm = forwardRef<FormRefActions, PayoutModelFormProps>(
	(
		{
			simulate,
			updatedData,
			auszahlungsoption,
			rueckkonvertierbareRente,
			handleFieldActive,
			selectedPayoutModel,
			handleSelectedPayoutModelChange,
			model,
			activeFields,
			summen,
			onSubmit,
			getFinalBalanceClass,
			hasRueckkonvertierbareRente,
		},
		ref
	) => {
		const auszahlungsModelFields = useMemo(() => {
			const auszahlungsModell = auszahlungsoption?.auszahlungsModell?.type

			if (!auszahlungsoption || auszahlungsModell === undefined) {
				return {}
			}

			//TODO: Textinput Component um eine Mask erweitern, ähnlich wie bei der BigNumberInput Komponente, um die convertToNumber Function zu ersetzen.

			let fields: FormFields
			fields = {
				rueckkonvertierbareRente: {
					fieldType: FormFieldType.text,
					hidden: !hasRueckkonvertierbareRente,
					type: TextInputType.text,
					value: currencyFormat(
						simulate ? rueckkonvertierbareRente.simuliert : rueckkonvertierbareRente.gewaehlt
					),
					disabled: true,
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.types.rueckkonvertierbareRente" />
					),
				},
				rueckkonvertierbareRenteMonatlich: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					hidden: !hasRueckkonvertierbareRente,
					value: currencyFormat(
						simulate
							? rueckkonvertierbareRente.simuliert === 0
								? 0
								: auszahlungsoption.auszahlungen.reconvertiblePension?.monatlicheRente
							: auszahlungsoption.auszahlungen.reconvertiblePension?.monatlicheRente
					),
					disabled: true,
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payment.monthlyPension" />,
				},
				desiredPaymentOptions: {
					fieldType: simulate ? FormFieldType.select : FormFieldType.text,
					type: simulate ? undefined : TextInputType.text,
					alternativeStyle: simulate ? true : undefined,
					options: simulate
						? [
								{
									label: t<string>('component.confirmedAuszahlungOption.modell.types.mixed'),
									value: 'mixed',
								},
								{
									label: t<string>('component.confirmedAuszahlungOption.modell.types.lumpSum'),
									value: 'lumpSum',
								},
								{
									label: t<string>(
										'component.confirmedAuszahlungOption.modell.types.instalments10Years'
									),
									value: 'instalments10Years',
								},
								{
									label: t<string>(
										'component.confirmedAuszahlungOption.modell.types.instalments20Years'
									),
									value: 'instalments20Years',
								},
						  ]
						: [],
					value: simulate
						? auszahlungsoption.auszahlungsModell?.type
						: t<string>(
								`component.confirmedAuszahlungOption.modell.types.${auszahlungsoption.auszahlungsModell?.type}`
						  ),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.desiredPaymentOptions" />
					),
					disabled: simulate ? isAMFormFieldActive(auszahlungsModell, 'desiredPaymentOptions') : true,
					className: simulate ? 'payoutModelSimulieren__dark enabledFormField' : '',
					hidden: simulate ? false : isAMFormFieldHidden(auszahlungsModell, 'desiredPaymentOptions'),
					onChange: (e: any) => {
						handleSelectedPayoutModelChange(e.target.value)
					},
				},
				instalments10Years: {
					fieldType: FormFieldType.select,
					alternativeStyle: true,
					onChange: (e: any) => handleFieldActive('is10YearsActive', e.target.value),
					options: [
						{ label: t<string>('component.confirmedAuszahlungOption.ja'), value: 'ja' },
						{ label: t<string>('component.confirmedAuszahlungOption.nein'), value: 'nein' },
					],
					value: simulate ? undefined : model?.has10Jahresraten ? 'ja' : 'nein',
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payment.instalments10Years" />,
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'instalments10Years')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.has10Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'instalments10Years'),
					className: simulate
						? selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments10Years'
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				totalAmountInstalments10Years: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption?.auszahlungen?.instalments10Years?.gesamtBetrag),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.totalAmountInstalments10Years" />
					),
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'instalments10Years')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.has10Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'totalAmountInstalments10Years'),
					className: simulate
						? !activeFields.is10YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments10Years')
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				instalments10YearsRatePerYear: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption?.auszahlungen?.instalments10Years?.jaehrlicheRate),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.instalments10YearsRatePerYear" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has10Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'instalments10YearsRatePerYear'),
					className: simulate
						? !activeFields.is10YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments10Years')
							? 'disabledFormField'
							: ''
						: '',
				},
				amountFirstInstalment10Years: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption?.auszahlungen?.instalments10Years?.ersteRate),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.amountFirstInstalment" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has10Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'amountFirstInstalment10Years'),
					className: simulate
						? !activeFields.is10YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments10Years')
							? 'disabledFormField'
							: ''
						: '',
				},
				amountFirstInstalment10YearsMultiple10: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(
						auszahlungsoption?.auszahlungen?.instalments10Years?.ersteRate
							? auszahlungsoption.auszahlungen.instalments10Years.ersteRate * 10
							: undefined
					),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.amountFirstInstalment10YearsMultiple10" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has10Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'amountFirstInstalment10Years'),
					className: simulate
						? !activeFields.is10YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments10Years')
							? 'disabledFormField'
							: ''
						: '',
				},
				remainingBalance: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(summen?.sum1),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.remainingBalance" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has10Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'remainingBalance'),
					className: simulate ? 'payoutModelSimulieren__remaining-balance' : 'payoutModel__remaining-balance',
				},
				instalments20Years: {
					fieldType: FormFieldType.select,
					alternativeStyle: true,
					onChange: (e: any) => handleFieldActive('is20YearsActive', e.target.value),
					options: [
						{ label: t<string>('component.confirmedAuszahlungOption.ja'), value: 'ja' },
						{ label: t<string>('component.confirmedAuszahlungOption.nein'), value: 'nein' },
					],
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payment.instalments20Years" />,
					value: simulate ? undefined : model?.has20Jahresraten ? 'ja' : 'nein',
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'instalments20Years')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.has20Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'instalments20Years'),
					className: simulate
						? selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments20Years'
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				totalAmountInstalments20Years: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption?.auszahlungen?.instalments20Years?.gesamtBetrag),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.totalAmountInstalments20Years" />
					),
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'instalments20Years')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.has20Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'instalments20YearsRatePerYear'),
					className: simulate
						? !activeFields.is20YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments20Years')
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				instalments20YearsRatePerYear: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption?.auszahlungen?.instalments20Years?.jaehrlicheRate),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.instalments20YearsRatePerYear" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has20Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'instalments20YearsRatePerYear'),
					className: simulate
						? !activeFields.is20YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments20Years')
							? 'disabledFormField'
							: ''
						: '',
				},
				amountFirstInstalment20Years: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption?.auszahlungen?.instalments20Years?.ersteRate),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.amountFirstInstalment" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has20Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'amountFirstInstalment20Years'),
					className: simulate
						? !activeFields.is20YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments20Years')
							? 'disabledFormField'
							: ''
						: '',
				},
				amountFirstInstalment20YearsMultiple20: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(
						auszahlungsoption?.auszahlungen?.instalments20Years?.ersteRate
							? auszahlungsoption.auszahlungen.instalments20Years.ersteRate * 20
							: undefined
					),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.amountFirstInstalment20YearsMultiple20" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has20Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'amountFirstInstalment20Years'),
					className: simulate
						? !activeFields.is10YearsActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments20Years')
							? 'disabledFormField'
							: ''
						: '',
				},
				remainingBalance2: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(summen?.sum2),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.remainingBalance" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.has20Jahresraten
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'remainingBalance2'),
					className: simulate ? 'payoutModelSimulieren__remaining-balance' : 'payoutModel__remaining-balance',
				},
				lumpSum: {
					fieldType: FormFieldType.select,
					alternativeStyle: true,
					onChange: (e: any) => handleFieldActive('isLumpSumActive', e.target.value),
					options: [
						{ label: t<string>('component.confirmedAuszahlungOption.ja'), value: 'ja' },
						{ label: t<string>('component.confirmedAuszahlungOption.nein'), value: 'nein' },
					],
					value: simulate ? undefined : model?.hasEinmalzahlung ? 'ja' : 'nein',
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.lumpSum" />,
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'lumpSum')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.hasEinmalzahlung
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'lumpSum'),
					className: simulate
						? selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'lumpSum'
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				lumpSumTotal: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption.auszahlungen.lumpSum?.gesamtBetrag),
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.oneTimeAmount" />,
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'lumpSum')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.hasEinmalzahlung
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'lumpSum'),
					className: simulate
						? !activeFields.isLumpSumActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'lumpSum')
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				lumpSumVerzinst: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(auszahlungsoption.auszahlungen.lumpSum?.ersteRate),
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.oneTimeVerzinst" />,
					disabled:
						simulate && (selectedPayoutModel === 'mixed' || selectedPayoutModel === 'lumpSum')
							? false
							: true,
					hidden: simulate
						? false
						: !model?.hasEinmalzahlung
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'lumpSum'),
					className: simulate
						? !activeFields.isLumpSumActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'lumpSum')
							? 'disabledFormField'
							: 'enabledFormField'
						: '',
				},
				remainingBalanceFinal: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: currencyFormat(summen?.finalsum),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.remainingBalance0" />
					),
					disabled: true,
					hidden: simulate ? false : isAMFormFieldHidden(auszahlungsModell, 'remainingBalanceFinal'),
					className: `payoutModel${
						simulate ? 'Simulieren' : ''
					}__remaining-balance ${getFinalBalanceClass()}`,
				},
				dateOneTimeAmount: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: dateFormat(new Date(auszahlungsoption?.datumFuerEinmalzahlung)),
					label: (
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.dateOneTimeAmount" />
					),
					disabled: true,
					hidden: simulate
						? false
						: !model?.hasEinmalzahlung
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'dateOneTimeAmount'),
					className: simulate
						? !activeFields.isLumpSumActive ||
						  (selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'lumpSum')
							? 'disabledFormField'
							: ''
						: '',
				},
				dateFirstRate: {
					fieldType: FormFieldType.text,
					type: TextInputType.text,
					value: dateFormat(new Date(auszahlungsoption?.datumErsteRate)),
					label: <Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.dateFirstRate" />,
					disabled: true,
					hidden: simulate
						? false
						: !(model?.has10Jahresraten || model?.has20Jahresraten)
						? true
						: isAMFormFieldHidden(auszahlungsModell, 'dateFirstRate'),
					className: simulate
						? (!activeFields.is20YearsActive ||
								(selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments20Years')) &&
						  (!activeFields.is10YearsActive ||
								(selectedPayoutModel !== 'mixed' && selectedPayoutModel !== 'instalments10Years'))
							? 'disabledFormField'
							: ''
						: '',
				},
			}

			return fields
		}, [
			auszahlungsoption,
			simulate,
			rueckkonvertierbareRente.simuliert,
			rueckkonvertierbareRente.gewaehlt,
			model?.has10Jahresraten,
			model?.has20Jahresraten,
			model?.hasEinmalzahlung,
			selectedPayoutModel,
			activeFields.is10YearsActive,
			activeFields.is20YearsActive,
			activeFields.isLumpSumActive,
			summen?.sum1,
			summen?.sum2,
			summen?.finalsum,
			getFinalBalanceClass,
			handleSelectedPayoutModelChange,
			handleFieldActive,
		])

		return (
			<Form
				updatedValues={updatedData}
				ref={ref}
				fields={auszahlungsModelFields as FormFields}
				hideSubmit={simulate ? false : true}
				onSubmit={onSubmit}
				className={simulate ? 'payoutModelSimulieren' : 'payoutModel'}
				promiseTracker={{ area: 'updateAustrittsAuszahlungsmodell' }}
			></Form>
		)
	}
)
