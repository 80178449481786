import { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { ViewHeader } from '../components/ViewHeader'
import GridList from '../components/GridList'
import GridListRow from '../components/GridListRow'
import GridListCell from '../components/GridListCell'
import useConnectApi, { IConnectMetrics } from '../hooks/useConnectApi'

const CallerInformation = () => {
	const [metrics, setMetrics] = useState<IConnectMetrics[]>([])
	const { getCurrentMetrics } = useConnectApi()

	// Function to convert seconds to minutes
	function toMinutes(totalSeconds: number) {
		if (!totalSeconds) {
			return 0
		}

		const result = new Date(totalSeconds * 1000).toISOString().slice(11, 19)
		return result
	}

	// Function to extract metric value
	const extractMetricValue = (metrics: IConnectMetrics['metrics'], name: string) => {
		const metric = metrics.find((m) => m.Name === name)
		if (metric) {
			return metric.Value
		}
		return 0
	}

	useEffect(() => {
		// Function to fetch metrics
		const fetchMetrics = async () => {
			const currentMetrics = await getCurrentMetrics()
			if (currentMetrics) {
				setMetrics(currentMetrics)
			}
		}

		fetchMetrics()

		// Set interval to fetch metrics every 12 seconds
		const fetchInterval = setInterval(fetchMetrics, 12000)
		// Set interval to increment oldest contact age every second
		const incrementInterval = setInterval(() => {
			setMetrics((prevMetrics) => {
				return prevMetrics
					.map((metric) => {
						const oldestContactAge = extractMetricValue(metric.metrics, 'OLDEST_CONTACT_AGE')
						if (oldestContactAge > 0) {
							return {
								...metric,
								metrics: metric.metrics.map((metric) => {
									if (metric.Name === 'OLDEST_CONTACT_AGE') {
										return {
											...metric,
											Value: metric.Value + 1,
										}
									}
									return metric
								}),
							}
						}
						return metric
					})
					.sort((a, b) => {
						const aQueueTime = extractMetricValue(a.metrics, 'OLDEST_CONTACT_AGE')
						const bQueueTime = extractMetricValue(b.metrics, 'OLDEST_CONTACT_AGE')
						return bQueueTime - aQueueTime
					})
			})
		}, 1000)
		return () => {
			clearInterval(fetchInterval)
			clearInterval(incrementInterval)
		}
	}, [getCurrentMetrics])

	const setQueuenameColor = (queueTime: number) => {
		if (queueTime <= 120) {
			return 'green'
		}
		if (queueTime <= 300) {
			return 'orange'
		}
		return 'red'
	}

	const headersCells = useMemo(
		() => [
			<Trans i18nKey={'view.callerInformation.tableHeaders.queue'} />,
			<Trans i18nKey={'view.callerInformation.tableHeaders.queueCount'} />,
			<Trans i18nKey={'view.callerInformation.tableHeaders.queueTime'} />,
			<Trans i18nKey={'view.callerInformation.tableHeaders.onlineAgents'} />,
			<Trans i18nKey={'view.callerInformation.tableHeaders.availableAgents'} />,
			<Trans i18nKey={'view.callerInformation.tableHeaders.agentsInCall'} />,
		],
		[]
	)
	const bodyRows = useMemo(() => {
		if (!metrics.length) {
			return null
		}
		return metrics.map(({ queueName, queueId, metrics }) => {
			const queueTime = extractMetricValue(metrics, 'OLDEST_CONTACT_AGE')

			return (
				<GridListRow key={queueId}>
					<GridListCell>{queueName}</GridListCell>
					<GridListCell>{extractMetricValue(metrics, 'CONTACTS_IN_QUEUE')}</GridListCell>
					<GridListCell style={{ color: `var(--color-${setQueuenameColor(queueTime)})` }}>
						{toMinutes(queueTime)}
					</GridListCell>
					<GridListCell>{extractMetricValue(metrics, 'AGENTS_ONLINE')}</GridListCell>
					<GridListCell>{extractMetricValue(metrics, 'AGENTS_AVAILABLE')}</GridListCell>
					<GridListCell>{extractMetricValue(metrics, 'AGENTS_ON_CALL')}</GridListCell>
				</GridListRow>
			)
		})
	}, [metrics])

	const avarageValuesRows = useMemo(() => {
		if (!metrics.length) {
			return null
		}
		const avarageValues = metrics.reduce(
			(acc, { metrics }) => {
				acc.contactsInQueue += extractMetricValue(metrics, 'CONTACTS_IN_QUEUE')
				acc.oldestContactAge += extractMetricValue(metrics, 'OLDEST_CONTACT_AGE')
				acc.agentsOnline += extractMetricValue(metrics, 'AGENTS_ONLINE')
				acc.agentsAvailable += extractMetricValue(metrics, 'AGENTS_AVAILABLE')
				acc.agentsOnCall += extractMetricValue(metrics, 'AGENTS_ON_CALL')
				return acc
			},
			{
				contactsInQueue: 0,
				oldestContactAge: 0,
				agentsOnline: 0,
				agentsAvailable: 0,
				agentsOnCall: 0,
			}
		)

		return (
			<GridListRow>
				<GridListCell></GridListCell>
				<GridListCell>{avarageValues.contactsInQueue}</GridListCell>
				<GridListCell>{toMinutes(avarageValues.oldestContactAge / metrics.length)}</GridListCell>
				<GridListCell>{avarageValues.agentsOnline}</GridListCell>
				<GridListCell>{avarageValues.agentsAvailable}</GridListCell>
				<GridListCell>{avarageValues.agentsOnCall}</GridListCell>
			</GridListRow>
		)
	}, [metrics])

	return (
		<div className="caller-information">
			<ViewHeader
				headline={<Trans i18nKey={'view.callerInformation.navigationName'} />}
				subheadline={<Trans i18nKey={'view.dashboard.callerInformation.subheadline'} />}
			/>
			{metrics.length === 0 ? (
				<Trans i18nKey={'view.callerInformation.error'} />
			) : (
				<GridList columnCount={4}>
					<GridListRow>
						{headersCells.map((v, i) => (
							<GridListCell key={`caller-information-header-${i}`} className={'grid-list__cell--header'}>
								{v}
							</GridListCell>
						))}
					</GridListRow>
					{bodyRows}
					{avarageValuesRows}
				</GridList>
			)}
		</div>
	)
}

export default CallerInformation
