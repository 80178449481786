import { TFunction } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat } from 'shared/helper/numberFormats'
import {
	AuszahlungsOptionAddress,
	AuszahlungsOptionBankingAccount,
	AuszahlungsOptionConfirmed,
	AuszahlungsOptionContact,
	AuszahlungsOptionIdentity,
	AuszahlungsOptionKrankenkasse,
	AuszahlungsOptionLeistungsart,
	AuszahlungsOptionModell,
	AuszahlungsOptionSozialVersicherung,
	AuszahlungsOptionTax,
	AuszahlungsoptionPflegeversicherung,
} from './model/auszahlungsoption/auszahlungs-option-confirmed.model'
import { components } from 'types/api-interface'

const processValue = (key: string, value: any, subsection: string, t: TFunction<'translation'>, austrittsDatum?: components["schemas"]["AustrittStammdaten"]["austrittsdatum"] ) => {
	if (!value) {
		return '-'
	}
	switch (key) {
		case 'staendigerWohnsitzInDeutschland':
		case 'elterneigenschaft':
		case 'payoutEmployerContribution':
			return value ? <Trans i18nKey="generic.yes" /> : <Trans i18nKey="generic.no" />
		case 'austrittsdatum':
			if (value) {
				const date = dateFormat(new Date(value.year, value.month - 1, value.day))
				const actualDate = getActualAustrittsdatum(date, austrittsDatum)
				return actualDate
			} else {
				return '-'
			}
		case 'birthday':
			if (value) {
				const date = new Date(value)
				return dateFormat(date)
			} else {
				return '-'
			}
		case 'konfession':
			switch (value) {
				case 'el':
				case 'ev':
				case 'rk':
					return t(`component.confirmedAuszahlungOption.konfession.${value}`)
				default:
					return value
			}
		case 'type':
			return <Trans i18nKey={`component.confirmedAuszahlungOption.${subsection}.types.${value}`} />
		case 'erwerbsminderungType':
			return <Trans i18nKey={`component.confirmedAuszahlungOption.leistungsart.erwerbsminderungTypes.${value}`} />
		default:
			return value
	}
}

const getActualAustrittsdatum = (dateFromValue: string, austrittsDatum?: components["schemas"]["AustrittStammdaten"]["austrittsdatum"]) => {
	if(!austrittsDatum) {
		return dateFromValue;
	}
	const formatedAustrittsDatum = dateFormat(new Date(austrittsDatum))
	if(formatedAustrittsDatum !== dateFromValue) {
		return formatedAustrittsDatum
	}
	return dateFromValue
}

interface IConfirmedAuszahlungOptionEntry {
	subsection: string
	name: string
	value: any
}

const ConfirmedAuszahlungOptionEntry: React.FC<IConfirmedAuszahlungOptionEntry & {austrittsDatum?: components["schemas"]["AustrittStammdaten"]["austrittsdatum"]}> = ({ subsection, name, value, austrittsDatum }) => {
	const { t } = useTranslation()
	return (
		<div className="pensioner-confirmed-auszahlung-option__entry">
			<div className="bold-small-heading">
				<Trans i18nKey={`component.confirmedAuszahlungOption.${subsection}.${name}`} />
			</div>
			<div className="user-select--text">{processValue(name, value, subsection, t, austrittsDatum)}</div>
		</div>
	)
}

type IConfirmedAuszahlungOptionSubsection =
	| Leistungsart
	| Modell
	| Identity
	| Address
	| Contact
	| BankingAccount
	| Tax
	| Sozialversicherung
	| Krankenkasse
	| pflegeversicherung

const ConfirmedAuszahlungOptionSubsection: React.FC<IConfirmedAuszahlungOptionSubsection & {austrittsDatum?: components["schemas"]["AustrittStammdaten"]["austrittsdatum"]}> = ({ name, data, austrittsDatum }) => {
	const dataObject = Object(data)
	const keys = data === undefined ? [] : Object.keys(data)
	return (
		<div className="pensioner-confirmed-auszahlung-option__subsection margin--horizontal margin--extra-large no-margin--right">
			<h3>
				<Trans i18nKey={`component.confirmedAuszahlungOption.${name}.label`} />
			</h3>
			<div>
				{keys.map((key) => {
					return (
						<div key={key}>
							<ConfirmedAuszahlungOptionEntry subsection={name} name={key} value={dataObject[key]} austrittsDatum={austrittsDatum}/>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const buildAuszahlungsModell = (modell: AuszahlungsOptionModell | null | undefined) => {
	const payments = new Array<[String, String]>()
	const emptyModell: AuszahlungsOptionModell = {
		type: null,
		absoluteAmounts: {},
		distribution: {},
	}
	const { type, absoluteAmounts, distribution } = modell ? modell : emptyModell
	if (absoluteAmounts) {
		Object.keys(absoluteAmounts).forEach((key) => {
			const amount = absoluteAmounts[key]
			if (amount) {
				const value = currencyFormat(amount)
				payments.push([key, value])
			}
		})
	}

	if (distribution) {
		Object.keys(distribution).forEach((key) => {
			const parts = distribution[key]
			if (parts) {
				const value = parts.toFixed(2).replace('.', ',') + '%'
				payments.push([key, value])
			}
		})
	}

	return (
		<div className="pensioner-confirmed-auszahlung-option__subsection">
			<h3>
				<Trans i18nKey={`component.confirmedAuszahlungOption.modell.label`} />
			</h3>
			<div>
				<div className="pensioner-confirmed-auszahlung-option__entry">
					<div className="bold-small-heading">
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.label" />
					</div>

					<div className="user-select--text">
						{type ? <Trans i18nKey={`component.confirmedAuszahlungOption.modell.types.${type}`} /> : '-'}
					</div>
				</div>
				{payments && (
					<div>
						{payments.map(([key, value]) => {
							return (
								<div className="pensioner-confirmed-auszahlung-option__entry">
									<div className="bold-small-heading">
										<Trans i18nKey={`component.confirmedAuszahlungOption.modell.payment.${key}`} />:
									</div>
									<div>{value}</div>
								</div>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}

const ConfirmedAuszahlungOption: React.FC<{ data: AuszahlungsOptionConfirmed, austrittsDatum: components["schemas"]["AustrittStammdaten"]["austrittsdatum"] | undefined }> = ({ data, austrittsDatum}) => {
	const {
		leistungsart,
		modell,
		identity,
		address,
		bankingAccount,
		tax,
		contact,
		sozialversicherung,
		krankenkasse,
		pflegeversicherung,
	} = data
	return (
		(data && Object.keys(data).length > 0 && (
			<>
				<h2 className="flex flex--align-items-center margin--vertical margin--large">
					<Trans i18nKey="component.confirmedAuszahlungOption.sectionOneHeader" />
				</h2>
				<div className="margin--horizontal margin--large margin--large padding--bottom">
						<Trans i18nKey="component.confirmedAuszahlungOption.disclaimer" />
				</div>
				<div className="pensioner-confirmed-auszahlung-option__section">
					<ConfirmedAuszahlungOptionSubsection name="leistungsart" data={leistungsart} austrittsDatum={austrittsDatum}/>
					{buildAuszahlungsModell(modell)}
				</div>

				<h2 className="flex flex--align-items-center margin--vertical margin--large">
					<Trans i18nKey="component.confirmedAuszahlungOption.personalDataSectionHeader" />
				</h2>
				<div className="pensioner-confirmed-auszahlung-option__section">
					<ConfirmedAuszahlungOptionSubsection name="identity" data={identity}/>
					<ConfirmedAuszahlungOptionSubsection name="address" data={address}/>
					<ConfirmedAuszahlungOptionSubsection name="contact" data={contact}/>
					<ConfirmedAuszahlungOptionSubsection name="bankingAccount" data={bankingAccount}/>
					<ConfirmedAuszahlungOptionSubsection name="tax" data={tax}/>
					<ConfirmedAuszahlungOptionSubsection name="krankenkasse" data={krankenkasse}/>
					<ConfirmedAuszahlungOptionSubsection name="pflegeversicherung" data={pflegeversicherung}/>
					<ConfirmedAuszahlungOptionSubsection name="sozialversicherung" data={sozialversicherung}/>
				</div>
			</>
		)) || (
			<>
				<h2 className="flex flex--align-items-center margin--vertical margin--large">
					<Trans i18nKey="component.confirmedAuszahlungOption.sectionOneHeader" />
				</h2>
				<div className="margin--horizontal margin--large margin--large padding--bottom">
						<Trans i18nKey="component.confirmedAuszahlungOption.disclaimer" />
				</div>
				<div className="margin--horizontal margin--extra-large no-margin--right">
					<Trans i18nKey="generic.noDataAvailable" />
				</div>
				<h2 className="flex flex--align-items-center margin--vertical margin--large">
					<Trans i18nKey="component.confirmedAuszahlungOption.personalDataSectionHeader" />
				</h2>
				<div className="margin--horizontal margin--extra-large no-margin--right">
					<Trans i18nKey="generic.noDataAvailable" />
				</div>
			</>
		)
	)
}

export default ConfirmedAuszahlungOption

type Leistungsart = {
	name: 'leistungsart'
	data: AuszahlungsOptionLeistungsart | undefined
}

type Modell = {
	name: 'modell'
	data: AuszahlungsOptionModell | undefined
}

type Identity = {
	name: 'identity'
	data: AuszahlungsOptionIdentity | undefined
}

type Address = {
	name: 'address'
	data: AuszahlungsOptionAddress | undefined
}

type Contact = {
	name: 'contact'
	data: AuszahlungsOptionContact | undefined
}

type BankingAccount = {
	name: 'bankingAccount'
	data: AuszahlungsOptionBankingAccount | undefined
}

type Tax = {
	name: 'tax'
	data: AuszahlungsOptionTax | undefined
}

type Sozialversicherung = {
	name: 'sozialversicherung'
	data: AuszahlungsOptionSozialVersicherung | undefined
}

type Krankenkasse = {
	name: 'krankenkasse'
	data: AuszahlungsOptionKrankenkasse | undefined
}

type pflegeversicherung = {
	name: 'pflegeversicherung'
	data: AuszahlungsoptionPflegeversicherung | undefined
}
