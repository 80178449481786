import useApi, { CompensationStatus, QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CompensationDataTable from 'shared/components/CompensationDataTable'
import CompensationShares from 'shared/components/CompensationShares'
import { ConfirmedCompensationType } from 'shared/enums'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat, percentFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useViewTitle } from 'state/useNavigationState'

const PensionerConversionsSingleMonth: React.FC<{ year: number; month: number }> = ({ year, month }) => {
	const { getParentPath } = useRouteHelper()
	const { t } = useTranslation()
	useViewTitle(
		t('view.pensionerConversions.conversion', {
			year,
			month: dateFormat(new Date(Date.UTC(0, month! - 1)), { month: 'long' }),
		})
	)
	useBackButtonPath(getParentPath())

	const { userId } = useUserRecord()

	const api = useApi()
	const { data, status } = useQuery(
		[
			QueryKey.compensationMonth,
			{
				userId,
				year,
				month,
			},
		],
		api.getSingleCompensation,
		{
			enabled: !!userId,
		}
	)
	const thisYear = new Date().getFullYear()
	const thisMonth = new Date().getMonth()
	const compensationLiesInThePast =
		new Date(thisYear, thisMonth, 1).getTime() > new Date(year, month - 1, 1).getTime()
	const compensationIsNotPerformed =
		(data && CompensationStatus.open === data.status && compensationLiesInThePast) ||
		(data && CompensationStatus.confirmed === data.status && data.disclaimer === 'ABGELEHNT')
	const compensationIsNotConfirmed = data && CompensationStatus.open === data.status && !compensationLiesInThePast

	const renderHeadline = useMemo(() => {
		if (undefined === data) {
			return null
		}
		const headline = compensationIsNotPerformed
			? t('view.pensionerConversions.singleMonth.headline.notperformed')
			: compensationIsNotConfirmed
			? t('view.pensionerConversions.singleMonth.headline.unconfirmed')
			: t('view.pensionerConversions.singleMonth.headline.confirmed')

		return <h3>{headline}:</h3>

		// eslint-disable-next-line
	}, [data])

	return (
		<div
			className={`flex flex--direction-column margin--vertical no-margin--top visually-hidden visually-hidden--${String(
				status !== 'success'
			)}`}
		>
			{data && (
				<>
					<div className="margin--vertical">
						{renderHeadline}
						{data.einzelBeitraege &&
							data.einzelBeitraege.map((einzelBeitrag, index: number) => {
								const { wandlungsart, wandlungsWunsch } = einzelBeitrag
								return (
									wandlungsWunsch && (
										<React.Fragment key={`bonus-${index}`}>
											<p className="text-color-gold">
												<b>
													<Trans
														i18nKey={`view.pensionerConversions.singleMonth.${
															CompensationStatus.open === data.status
																? 'unconfirmed'
																: 'confirmed'
														}${
															wandlungsart === ConfirmedCompensationType.MONTHLY
																? 'CompensationAmount'
																: 'SingleCompensationAmount'
														}`}
														values={{
															compensationType: t(
																`generic.confirmedCompensationTypes.${wandlungsart}`
															),
														}}
													/>
													:{' '}
													{wandlungsWunsch.einheit === 'PROZENT'
														? percentFormat(wandlungsWunsch.wert)
														: wandlungsWunsch.einheit === 'EURO'
														? currencyFormat(wandlungsWunsch.wert)
														: ''}
												</b>
											</p>
										</React.Fragment>
									)
								)
							})}
						<p className="margin--vertical">
							<Trans
								i18nKey="view.pensionerConversions.singleMonth.salary"
								values={{
									salary: currencyFormat(data.bemessungsgrundlage, { variableFractionDigits: true }),
								}}
							/>
						</p>
					</div>

					{CompensationStatus.confirmed === data.status && (
						<h3 className="margin--vertical">
							<Trans i18nKey="view.pensionerConversions.singleMonth.headline.confirmedCompensation" />:
						</h3>
					)}

					<CompensationShares
						ownContribution={data.eigenbeitrag}
						employerContribution={(data?.arbeitgeberGrundbeitrag || 0) + (data?.matchingBeitrag || 0)}
						sum={
							(data?.eigenbeitrag || 0) +
							(data?.arbeitgeberGrundbeitrag || 0) +
							(data?.matchingBeitrag || 0)
						}
					/>
					<CompensationDataTable data={data} liesInThePast={compensationLiesInThePast} />
				</>
			)}
		</div>
	)
}

export default PensionerConversionsSingleMonth
