import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import Modal, { ModalRefActions } from 'shared/components/Modal'

export const LeistungsFallMarkingButton = ({ disabled }: { disabled: boolean }) => {
	const { userId } = useUserRecord()
	const api = useApi()
	const queryClient = useQueryClient()
	const [openModal, setOpenModal] = useState(false)
	const { t } = useTranslation()

	const { data } = useQuery({
		queryKey: [QueryKey.leistungsfallMarkingGET, { userId }],
		queryFn: () => api.getLeistungsfallMarking(userId),
		enabled: !!userId,
	})

	const { mutate: setLeistungsfallMark, status } = useMutation({
		mutationFn: () => api.putLeistungsfallMarking(userId),
		onSuccess: () => {
			queryClient.invalidateQueries([QueryKey.leistungsfallMarkingGET, { userId }])
			queryClient.invalidateQueries([QueryKey.austrittsbearbeitungen, { userId }])
		},
	})

	const markedAsLeistungsfall = useMemo(() => data?.markedAsLeistungsfall, [data])

	const handleClick = useCallback(() => {
		setLeistungsfallMark()
		if (status === 'success') {
			setOpenModal(false)
		}
	}, [setLeistungsfallMark, status])

	if (markedAsLeistungsfall) {
		return <></>
	}

	return (
		<>
			<Button
				className="margin--top"
				type={[ButtonType.highlight, ButtonType.small]}
				onClick={() => setOpenModal(true)}
				disabled={disabled}
			>
				{t('view.pensionerProfile.tabs.austrittsDaten.modal.markAsLeistungsfall')}
			</Button>
			<MarkingModal open={openModal} onClick={handleClick} setOpenModal={setOpenModal} />
		</>
	)
}

const MarkingModal: FunctionComponent<{ open: boolean; onClick: () => void; setOpenModal: (arg: boolean) => void }> = ({
	open,
	onClick,
	setOpenModal,
}) => {
	const modalRef = useRef<ModalRefActions>()
	const { t } = useTranslation()

	useEffect(() => {
		open ? modalRef.current?.openModal() : modalRef.current?.closeModal()
	}, [open])

	return (
		<Modal
			ref={modalRef}
			forceAction={false}
			header={t('view.pensionerProfile.tabs.austrittsDaten.modal.header')}
			buttonLabel={t(`generic.confirm`)}
			onModalClose={() => setOpenModal(false)}
			onButtonClick={onClick}
			className="modal--start-austrittstask modal--width-big"
			onCloseClick={() => setOpenModal(false)}
			hideConfirm
		>
			{t('view.pensionerProfile.tabs.austrittsDaten.modal.content')}
			<div className="flex flex--gap flex--justify-content-center">
				<Button
					promiseTracker={{ area: 'putLeistungsfallMarking' }}
					type={[ButtonType.highlight, ButtonType.small]}
					onClick={onClick}
				>
					{t('view.pensionerProfile.tabs.austrittsDaten.modal.labels.yes')}
				</Button>
				<Button type={[ButtonType.secondary, ButtonType.small]} onClick={() => setOpenModal(false)}>
					{t('view.pensionerProfile.tabs.austrittsDaten.modal.labels.no')}
				</Button>
			</div>
		</Modal>
	)
}
